/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/_nav.css';
import Logo from './SvgLogo';
import Burger from './SvgBurger';
import Cross from './SvgCross';
import Facebook_alt from './SvgFacebook_alt';

const NavBar = () => {
  const [isVisible, setVisible] = useState('false');

  const handleToggle = () => {
    setVisible(!isVisible);
  };

  return (
    <>
      <nav className={isVisible ? 'alt-menu' : 'alt-menu is-visible'}>
        <div className="container col-2">
          <div className="nav-buttons">
            <Link to="/" onClick={handleToggle}>
              Accueil
            </Link>
            <Link to="agenda" onClick={handleToggle}>
              Agenda
            </Link>
            <Link to="holy-grail" onClick={handleToggle}>
              Ressources
            </Link>
            <Link to="steamagine" onClick={handleToggle}>
              STEAMagine
            </Link>
            <Link to="sciencenum" onClick={handleToggle}>
              ScienceNum
            </Link>
            <Link to="blog" onClick={handleToggle}>
              Articles
            </Link>
            <div className="alt-link">
              <a href="/pdf/brochure.pdf" rel="noreferrer">
                Catalogue
              </a>
              <img src="svg/pdf.svg" alt="pdf" />
            </div>
          </div>
          <div className="contact-text">
            <p>
              Que ce soit pour des animations scolaires, extrascolaires, des
              événements ou encore parler d'un projet, l'équipe de Kodo Wallonie
              est disponible pour vous. Envoyez-nous un petit mail et nous y
              répondrons aussi vite que possible.
            </p>
            <a href="mailto:contact@kodowallonie.be">contact@kodowallonie.be</a>
            <a
              href="https://www.facebook.com/KodoWallonie/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="social-alt">
                <Facebook_alt />
              </div>
            </a>
          </div>
        </div>
        <div className="btn" onClick={handleToggle}>
          <div className="cross">
            <Cross />
          </div>
        </div>
      </nav>
      <nav className="main-menu">
        <Link to="/">
          <div className="logo">
            <Logo />
          </div>
        </Link>
        <div onClick={handleToggle}>
          <div className="burger">
            <Burger />
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
