import OnScreen from '../hooks/OnScreen';

const Privacy = () => {
  return (
    <>
      <OnScreen>
        <section id="privacy">
          <div className="wrapper small">
            <div className="container column">
              <h2 className="title-alt">Kodo Wallonie asbl</h2>
              <h2 className="subtitle">
                Définition et nature des données à caractère personnel
              </h2>
              <p className="text txt">
                Lors de votre utilisation de notre site ou lors de nos contacts
                mail ou téléphoniques, nous pouvons être amenés à vous demander
                de nous communiquer des données à caractère personnel vous
                concernant.
              </p>
              <p className="text txt">
                Le terme «données à caractère personnel» désigne toutes les
                données qui permettent d’identifier un individu, ce qui
                correspond notamment à vos nom, prénoms, pseudonyme,
                photographie, adresses postale et de courrier électronique,
                numéros de téléphone, date de naissance, données relatives à vos
                transactions sur un site, détails de vos achats et abonnements,
                numéros de carte bancaire, ainsi que tout autre renseignement
                que vous choisirez de nous communiquer à votre sujet.
              </p>
              <h2 className="subtitle">Objet de la présente charte</h2>
              <p className="text txt">
                La présente charte a pour objet de vous informer sur les moyens
                que nous mettons en œuvre pour collecter vos données à caractère
                personnel, dans le respect le plus strict de vos droits.
              </p>
              <p className="text txt">
                Nous vous indiquons à ce sujet que nous nous conformons, dans la
                collecte et la gestion de vos données à caractère personnel, au
                règlement européen 2016/679 dit Règlement Général pour la
                Protection des Données (ci-après, RGPD dans le texte), voté le
                27 avril 2016 par le Parlement européen et le Conseil.
              </p>
              <h2 className="subtitle">
                Identité du responsable de la collecte de données
              </h2>
              <p className="text txt">
                Le responsable de la collecte de vos données à caractère
                personnel est l'association Kodo Wallonie immatriculée à la
                Banque Carrefour des Entreprises sous le numéro BE 0642 939 457
                et ayant son siège social à Waremme.
              </p>
              <h2 className="subtitle">
                Collecte des données à caractère personnel
              </h2>
              <p className="text txt">
                Vos données à caractère personnel sont collectées pour répondre
                à une ou plusieurs des finalités suivantes :
              </p>
              <ul>
                <li className="text">
                  Effectuer les opérations relatives à la gestion des
                  fournisseurs et clients concernant les contrats, factures et
                  suivis de relation ;
                </li>
                <li className="text">
                  Constituer un fichier de membres inscrits, d’utilisateurs, de
                  clients et prospects;
                </li>
                <li className="text">
                  Adresser des newsletters et sollicitations. Dans le cas où
                  vous ne le souhaiteriez pas, nous vous donnons la faculté
                  d’exprimer votre refus à ce sujet lors de la collecte de vos
                  données;
                </li>
                <li className="text">
                  Élaborer des statistiques de nos activités et prédire nos
                  activités futures. Dans ce cas, il est possible de demander
                  l'anonymisation de vos données ;
                </li>
                <li className="text">
                  Organiser des jeux concours et opérations promotionnelles à
                  l’exclusion des jeux d’argent et de hasard en ligne soumis à
                  l’agrément de l’Autorité de Régulation des Jeux en ligne;
                </li>
                <li className="text">
                  Gérer la gestion des avis des personnes sur nos activités,
                  services ou contenus;
                </li>
                <li className="text">
                  Gérer les impayés et les contentieux éventuels quant à la
                  fréquentation de nos activités ;
                </li>
                <li className="text">
                  Respecter nos obligations légales et réglementaires.
                </li>
              </ul>
              <p className="text txt">
                Nous vous informons, lors de la collecte de vos données
                personnelles, si certaines données doivent être obligatoirement
                renseignées ou si elles sont facultatives. Nous vous indiquons
                également quelles sont les conséquences éventuelles d’un défaut
                de réponse.
              </p>
              <h2 className="subtitle">Destinataires des données collectées</h2>
              <p className="text txt">
                Le personnel de notre association et ses représentants
                pourraient avoir accès à vos données à caractère personnel.
              </p>
              <p className="text txt">
                Peuvent également être destinataires de vos données à caractère
                personnel les organismes publics, exclusivement pour répondre à
                nos obligations légales, les auxiliaires de justice, les
                officiers ministériels et les organismes chargés d’effectuer le
                recouvrement de créances.
              </p>
              <h2 className="subtitle">
                Cession des données à caractère personnel
              </h2>
              <p className="text txt">
                Vos données à caractère personnel pourront faire l’objet de
                cessions, locations ou échanges au bénéfice de tiers. Cette
                possible cession se fait dans un but non commercial et afin de
                répondre au mieux à nos missions et obligations légales.
              </p>
              <p className="text txt">
                Nous vous donnons la faculté de cocher une case exprimant votre
                accord à ce sujet lors de la collecte de vos données afin de
                répondre à l'article 7 du RGPD.
              </p>
              <h2 className="title-alt">
                Durée de conservation des données à caractère personnel
              </h2>
              <h2 className="subtitle">
                Concernant les données relatives à la gestion de clients et
                prospects :
              </h2>
              <p className="text txt">
                Vos données à caractère personnel ne seront pas conservées
                au-delà de la durée strictement nécessaire à la gestion de notre
                relation avec vous. Toutefois, les données permettant d’établir
                la preuve d’un droit ou d’un contrat, devant être conservées au
                titre du respect d’une obligation légale, le seront pendant la
                durée prévue par la loi en vigueur.
              </p>
              <p className="text txt">
                Concernant d’éventuelles opérations de prospection à destination
                des clients, leurs données pourront être conservées pendant un
                délai de deux ans à compter de la fin de la relation.
              </p>
              <p className="text txt">
                Les données à caractère personnel relatives à un prospect, non
                client, pourront être conservées pendant un délai de six mois à
                compter de leur collecte ou du dernier contact émanant du
                prospect. Au terme de ce délai de six mois, nous pourrons
                reprendre contact avec vous pour savoir si vous souhaitez
                continuer à recevoir des sollicitations.
              </p>
              <h2 className="subtitle">Concernant les pièces d’identité:</h2>
              <p className="text txt">
                En cas d’exercice du droit d’accès ou de rectification, nous
                sommes susceptibles de vous demander la confirmation de votre
                identité grâce à vos pièces d'identité. Ces données pourront
                être conservées pendant un an. En cas d’exercice du droit
                d’opposition, ces données peuvent être archivées pendant trois
                ans.
              </p>
              <h2 className="subtitle">
                Concernant les données relatives aux cartes bancaires :
              </h2>
              <p className="text txt">
                Les transactions financières relatives au paiement des achats et
                des frais via le site, sont confiées à un prestataire de
                services de paiement qui en assure le bon déroulement et la
                sécurité.
              </p>
              <p className="text txt">
                Pour les besoins des services, ce prestataire de services de
                paiement peut être amené à être destinataire de vos données à
                caractère personnel relatives à vos numéros de cartes bancaires,
                qu’il recueille et conserve en notre nom et pour notre compte.
                Nous n’avons pas accès à ces données.
              </p>
              <h2 className="subtitle">
                Concernant la gestion des listes d’opposition à recevoir de la
                prospection :
              </h2>
              <p className="text txt">
                Les informations permettant de prendre en compte votre droit
                d’opposition sont conservées au minimum trois ans à compter de
                l’exercice du droit d’opposition.
              </p>
              <h2 className="subtitle">
                Concernant les statistiques de mesure d’audience :
              </h2>
              <p className="text txt">
                Les informations stockées dans le terminal des utilisateurs ou
                tout autre élément utilisé pour identifier les utilisateurs et
                permettant leur traçabilité ou fréquentation ne seront pas
                conservées au-delà de 6 mois.
              </p>
              <p className="text txt">1. Sécurité</p>
              <p className="text txt">
                Nous vous informons prendre toutes précautions utiles, mesures
                organisationnelles et techniques appropriées pour préserver la
                sécurité, l’intégrité et la confidentialité de vos données à
                caractère personnel et notamment, empêcher qu’elles soient
                déformées, endommagées ou que des tiers non autorisés y aient
                accès.
              </p>
              <p className="text txt">2. Cookies</p>
              <p className="text txt">
                Les cookies sont des fichiers textes, souvent cryptés, stockés
                dans votre navigateur. Ils sont créés lorsque le navigateur d’un
                utilisateur charge un site internet donné : le site envoie des
                informations au navigateur, qui crée alors un fichier texte.
                Chaque fois que l’utilisateur revient sur le même site, le
                navigateur récupère ce fichier et l’envoie au serveur du site
                internet.
              </p>
              <p className="text txt">
                On peut distinguer deux types de cookies, qui n’ont pas les
                mêmes finalités : les cookies techniques et les cookies
                publicitaires :
              </p>
              <ul>
                <li className="text">
                  Les cookies techniques sont utilisés tout au long de votre
                  navigation, afin de la faciliter et d’exécuter certaines
                  fonctions. Un cookie technique peut par exemple être utilisé
                  pour mémoriser les réponses renseignées dans un formulaire ou
                  encore les préférences de l’utilisateur s’agissant de la
                  langue ou de la présentation d’un site internet, lorsque de
                  telles options sont disponibles
                </li>
                <li className="text">
                  Les cookies publicitaires peuvent être créés non seulement par
                  le site internet sur lequel l’utilisateur navigue, mais
                  également par d’autres sites internet diffusant des
                  publicités, annonces, widgets ou autres éléments sur la page
                  affichée. Ces cookies peuvent notamment être utilisés pour
                  effectuer de la publicité ciblée, c’est-à-dire de la publicité
                  déterminée en fonction de la navigation de l’utilisateur.
                </li>
              </ul>
              <p className="text txt">
                Nous utilisons des cookies techniques. Ceux-ci sont stockés dans
                votre navigateur pour une période qui ne peut excéder six mois.
              </p>
              <p className="text txt">
                Nous n’utilisons pas de cookies publicitaires. Toutefois, si
                nous devions en utiliser à l’avenir, nous vous en informerions
                au préalable et vous auriez la possibilité le cas échéant de
                désactiver ces cookies.
              </p>
              <p className="text txt">
                Nous pouvons utiliser Google Analytics qui est un outil
                statistique d’analyse d’audience qui génère un cookie permettant
                de mesurer le nombre de visites sur le site, le nombre de pages
                vues et l’activité des visiteurs. Votre adresse IP est également
                collectée pour déterminer la ville depuis laquelle vous vous
                connectez. La durée de conservation de ce cookie est mentionnée
                à l’article 7 de la présente charte.
              </p>
              <p className="text txt">
                Nous vous rappelons à toutes fins utiles qu’il vous est possible
                de vous opposer au dépôt de cookies en configurant votre
                navigateur. Un tel refus pourrait toutefois empêcher le bon
                fonctionnement du site.
              </p>
              <p className="text txt">3. Consentement</p>
              <p className="text txt">
                Lorsque vous choisissez de communiquer vos données à caractère
                personnel, vous devez donner votre consentement librement pour
                la collecte et l’utilisation de celles-ci dans les limites de
                l'usage utile et nécessaire conformément à l'article 6 §1 du
                RGPD.
              </p>
              <p className="text txt">
                4. Accès à vos données à caractère personnel
              </p>
              <p className="text txt">
                Vous disposez du droit d’obtenir la communication et, le cas
                échéant, la rectification ou la suppression des données vous
                concernant. Pour cela, vous pouvez vous adresser à :
              </p>
              <p className="text txt">
                Adresse de courrier électronique : contact@kodowallonie.be
              </p>
              <p className="text txt">
                Adresse de courrier postal : Rue Louis Maréchal, 48A à 4300
                Bleret.
              </p>
              <p className="text txt">
                Il est rappelé que toute personne peut, pour des motifs
                légitimes, s'opposer au traitement des données la concernant.
              </p>
              <p className="text txt">5. Modifications</p>
              <p className="text txt">
                Nous nous réservons le droit de modifier à tout moment la
                présente charte, en totalité ou en partie. Dans ce cas, vous en
                serez avertis par un message d'accueil ou par mail afin de
                marquer votre accord, ou non. Conformément à l'article 7 du
                RGPD, vous avez également le droit de retirer votre consentement
                à tout moment.
              </p>
              <p className="text txt">6. Entrée en vigueur</p>
              <p className="text txt">
                La présente charte est entrée en vigueur le 25 mai 2018.
              </p>
              <h2 className="title-alt">Mentions légales</h2>
              <h2 className="subtitle">Kodo Wallonie asbl</h2>
              <p className="text txt">
                Rue Louis Maréchal, 48a
                <br />
                4300 Bleret
              </p>
              <h2 className="subtitle">Références</h2>
              <p className="text txt">
                Entreprise 06.42.939.457 <br />
                TVA BE06.42.939.457
              </p>
            </div>
          </div>
        </section>
      </OnScreen>
    </>
  );
};

export default Privacy;
