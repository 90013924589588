import Agenda from './pages/Agenda';
import Blog from './pages/Blog';
import Error from './pages/Error';
import ExpSchools from './pages/ExpSchools';
import ExpCultural from './pages/ExpCultural';
import ExpFamilies from './pages/ExpFamilies';
import ExpCompanies from './pages/ExpCompanies';
import Home from './pages/Home';
import Nav from './comp/Nav';
import PostHead from './comp/PostHead';
import Privacy from './pages/Privacy';
import ScrollTop from './hooks/ScrollTop';
import Source from './pages/Source';
import { Link, Routes, Route } from 'react-router-dom';
import './styles/app.css';
import Facebook from './comp/SvgFacebook';
import STEAMagine from './pages/Steamagine';
import Soon from './pages/soon';
import ScienceNum from './pages/ScienceNum';

function App() {
  return (
    <>
      <ScrollTop />
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/agenda" element={<Agenda />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/post/:id" element={<PostHead />} />
        <Route path="/holy-grail" element={<Source />} />
        <Route path="/steamagine" element={<STEAMagine />} />
        <Route path="/sciencenum" element={<ScienceNum />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/schools" element={<ExpSchools />} />
        <Route path="/cultural" element={<ExpCultural />} />
        <Route path="/families" element={<ExpFamilies />} />
        <Route path="/companies" element={<ExpCompanies />} />
        <Route path="/soon" element={<Soon />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <footer>
        <a
          href="https://www.facebook.com/KodoWallonie/"
          target="_blank"
          rel="noreferrer"
        >
          <div className="social">
            <Facebook />
          </div>
        </a>
        <p>
          © 2024 Kodo Wallonie |<Link to="/privacy">Confidentialité</Link>
        </p>
      </footer>
    </>
  );
}

export default App;
