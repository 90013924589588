let linkData = [
  /*
  {
    id: '',
    title: '',
    category: '',
    description: '',
    color: '',
    link: '',
  },
  */
  {
    id: 'a-01',
    title: 'Code Karts (Android)',
    category: 'Dès 4 ans',
    description:
      'Application pour découvrir la logique algorithmique dès le plus jeune âge',
    color: 'purple',
    link: 'https://play.google.com/store/apps/details?id=com.edokiacademy.babycoding&hl=en_US&gl=US',
  },
  {
    id: 'a-02',
    title: 'Esprit sorcier',
    category: 'Dès 4 ans',
    description:
      "Un site web accompagné d'une chaine Youtube pour parler de sciences mais pas que... Des ressources pour mieux comprendre tout un tas de concepts",
    color: 'purple',
    link: 'https://www.lespritsorcier.org/',
  },
  {
    id: 'a-03',
    title: 'Heure du code',
    category: 'Dès 4 ans',
    description:
      "Pour débuter dans l'initiation à l'algorithmique grâce à de multiples possibilités d'activités",
    color: 'purple',
    link: 'https://code.org/',
  },
  {
    id: 'a-04',
    title: 'Lumni',
    category: 'Dès 4 ans',
    description:
      'Un site web et une chaine Youtube qui proposent des contenus adaptés aux programmes scolaires français',
    color: 'purple',
    link: 'https://www.lumni.fr/',
  },
  {
    id: 'a-05',
    title: 'Marie Duflot',
    category: 'Dès 4 ans',
    description:
      "Des exemples d'activités ludiques pour apprendre les concepts informatiques sans ordinateur",
    color: 'purple',
    link: 'https://www.youtube.com/playlist?list=PLWvGMqXvyJAPSMFgCiy6qVHW9bAPu93X5',
  },
  {
    id: 'a-06',
    title: 'Osmo',
    category: 'Dès 4 ans',
    description:
      'Kit de programmation accompagné de son application pour découvrir cette thématique en jouant',
    color: 'purple',
    link: 'https://www.playosmo.com/en/coding/',
  },
  {
    id: 'a-07',
    title: 'Qwant Junior',
    category: 'Dès 4 ans',
    description: 'Le moteur de recherche adapté aux enfants',
    color: 'purple',
    link: 'https://www.qwantjunior.com/',
  },
  {
    id: 'a-08',
    title: 'Scratch Jr (Android)',
    category: 'Dès 4 ans',
    description:
      "L'application pour débuter l'apprentissahe de la programmation dès le plus jeune âge",
    color: 'purple',
    link: 'https://play.google.com/store/apps/details?id=org.scratchjr.android',
  },
  {
    id: 'a-09',
    title: 'Scratch Jr (iOS)',
    category: 'Dès 4 ans',
    description:
      "L'application pour débuter l'apprentissahe de la programmation dès le plus jeune âge",
    color: 'purple',
    link: 'https://apps.apple.com/us/app/scratchjr/id895485086?ls=1',
  },
  {
    id: 'a-10',
    title: 'Scratch Jr (desktop)',
    category: 'Dès 4 ans',
    description:
      "L'application pour débuter l'apprentissahe de la programmation dès le plus jeune âge",
    color: 'purple',
    link: 'https://jfo8000.github.io/ScratchJr-Desktop/',
  },
  {
    id: 'a-11',
    title: 'Vikidia',
    category: 'Dès 4 ans',
    description: 'Une encyclopédie par et pour les enfants',
    color: 'purple',
    link: 'https://fr.vikidia.org/wiki/Vikidia:Accueil',
  },
  {
    id: 'a-12',
    title: 'Wikimini',
    category: 'Dès 4 ans',
    description: 'Une encyclopédie par et pour les enfants',
    color: 'purple',
    link: 'https://fr.wikimini.org/wiki/Accueil',
  },
  {
    id: 'b-01',
    title: '1 jour, 1 actu',
    category: 'Dès 7 ans',
    description:
      'Ce site recense une actualité la réponse à une question par jour',
    color: 'blue',
    link: 'https://www.1jour1actu.com/',
  },
  {
    id: 'b-02',
    title: 'CS Unplugged',
    category: 'Dès 7 ans',
    description:
      'Un double PDF reprenant des activités déconnectées pour découvrir des concepts informatiques',
    color: 'blue',
    link: 'https://www.csunplugged.org/fr/',
  },
  {
    id: 'b-03',
    title: 'LightBot',
    category: 'Dès 7 ans',
    description:
      'Application développant les compétences en algorithmique et faisant découvrir les concepts de programmation par le jeu',
    color: 'blue',
    link: 'https://lightbot.com/',
  },
  {
    id: 'b-04',
    title: 'Makecode Arcade',
    category: 'Dès 7 ans',
    description:
      'Plateforme de création de jeux vidéo rétrogaming. Possibilité de programmer en blocs, Javascript ou Python',
    color: 'blue',
    link: 'https://arcade.makecode.com/',
  },
  {
    id: 'b-05',
    title: 'Micro:bit',
    category: 'Dès 7 ans',
    description:
      'Plateforme de programmation de la carte micro:bit en blocs, Javascript ou Python',
    color: 'blue',
    link: 'https://www.microbit.org/',
  },
  {
    id: 'b-06',
    title: 'Run Marco',
    category: 'Dès 7 ans',
    description:
      "Jeu d'initiation à la logique algorithmique grâce à des pictogrammes",
    color: 'blue',
    link: 'https://runmarco.allcancode.com/',
  },
  {
    id: 'b-07',
    title: 'Scratch',
    category: 'Dès 7 ans',
    description:
      "Logiciel et plateforme de création d'animations interactives et de jeux vidéo avec un langage blocs adapté aux plus jeunes",
    color: 'blue',
    link: 'https://scratch.mit.edu/',
  },
  {
    id: 'b-08',
    title: 'Technobel',
    category: 'Dès 7 ans',
    description:
      'Centre de compétences abritant le Lego Education Innovation Studio et le MakerHub permettant la découverte de la robotique et des métiers techniques',
    color: 'blue',
    link: 'http://technobel.be/',
  },
  {
    id: 'b-09',
    title: 'Technofutur TIC',
    category: 'Dès 7 ans',
    description:
      "Ce centre de compétences intégrant l'EduLAB, propose également des stages lors des congés scolaires autour du numérique",
    color: 'blue',
    link: 'http://www.edu-lab.be/',
  },
  {
    id: 'b-10',
    title: 'Astro Pi : Mission zero',
    category: 'Dès 7 ans',
    description:
      'Mission préalable au projet Astro Pi proposé à des groupes de jeunes de 10 à 18 ans afin de créer un programme informatique pour la station spatiale internationale (ISS)',
    color: 'blue',
    link: 'https://projects.raspberrypi.org/en/projects/astro-pi-mission-zero',
  },
  {
    id: 'b-11',
    title: 'Blockly games',
    category: 'Dès 7 ans',
    description:
      'Plateforme regroupant plusieurs petits jeux simples pour apprendre la logique algorithmique',
    color: 'blue',
    link: 'https://blockly.games/?lang=fr',
  },
  {
    id: 'b-12',
    title: 'CoderDojo',
    category: 'Dès 7 ans',
    description:
      'Réseau de clubs de programmation gratuit pour les 7-18 ans. Rencontres une fois par mois dans différents lieux de la Fédération Wallonie-Bruxelles',
    color: 'blue',
    link: 'https://www.coderdojobelgium.be/fr',
  },
  {
    id: 'b-13',
    title: 'CS First',
    category: 'Dès 7 ans',
    description:
      "Programme d'initiation à la logique algorithmique et à la programmation par Google",
    color: 'blue',
    link: 'https://csfirst.withgoogle.com/s/fr/home',
  },
  {
    id: 'c-01',
    title: 'Swift Playground',
    category: 'Dès 11 ans',
    description:
      "Application iOS qui permet d'apprendre à programmer par niveaux, accompagnés de leur musique d'ambiance",
    color: 'green',
    link: 'https://www.apple.com/swift/playgrounds/',
  },
  {
    id: 'c-02',
    title: 'Coding Park',
    category: 'Dès 11 ans',
    description:
      'Plateforme web gratuite pour devenir un pirate et réaliser des aventures toutes en programmation !',
    color: 'green',
    link: 'https://codingpark.io/',
  },
  {
    id: 'c-03',
    title: 'Algo-bot',
    category: 'Dès 11 ans',
    description:
      "Jeu vidéo disponible sur ordinateur et mobile qui permet d'apprendre la logique algorithmique. Création de Technobel et Fishing Cactus",
    color: 'green',
    link: 'https://www.algobot.be/',
  },
  {
    id: 'c-04',
    title: 'App Inventor',
    category: 'Dès 11 ans',
    description:
      'Ce site web offre la possibilité de créer une application Android et de la tester. Accessible via un compte Gmail',
    color: 'green',
    link: 'http://appinventor.mit.edu/',
  },
  {
    id: 'c-05',
    title: 'Arduino',
    category: 'Dès 11 ans',
    description:
      "Les cartes Arduino sont des cartes programmables et peuvent être accompagnées d'accessoires pour créer des projets électroniques",
    color: 'green',
    link: 'https://www.arduino.cc/',
  },
  {
    id: 'c-06',
    title: 'Code Combat',
    category: 'Dès 11 ans',
    description:
      "Jeu vidéo où le joueur doit programmer son personnage pour qu'il réussisse des épreuves. Disponible en plusieurs langages différents",
    color: 'green',
    link: 'https://codecombat.com/home',
  },
  {
    id: 'c-07',
    title: 'Compute it',
    category: 'Dès 11 ans',
    description:
      "Mieux comprendre la syntaxe des langages de programmation grâce à un jeu ? C'est possible avec Compute it",
    color: 'green',
    link: 'http://compute-it.toxicode.fr/',
  },
  {
    id: 'c-08',
    title: 'Construct',
    category: 'Dès 11 ans',
    description:
      'Construct est un logiciel de création de jeux vidéo semi-professionnel',
    color: 'green',
    link: 'https://www.construct.net/fr',
  },
  {
    id: 'c-09',
    title: 'France IOI - Olympiades informatique',
    category: 'Dès 11 ans',
    description:
      "Site web des olympiades d'informatiques françaises avec plusieurs exercices de préparation disponibles pour tous",
    color: 'green',
    link: 'http://www.france-ioi.org/',
  },
  {
    id: 'c-10',
    title: 'Minecraft',
    category: 'Dès 11 ans',
    description:
      "Jeu vidéo de création et de survie, il peut être utilisé pour découvrir les sciences, l'informatique, etc",
    color: 'green',
    link: 'https://www.minecraft.net/fr-fr/',
  },
  {
    id: 'c-11',
    title: 'Minetest',
    category: 'Dès 11 ans',
    description:
      'Equivalent libre et gratuit de Minecraft où il faut un peu plus "bidouiller"',
    color: 'green',
    link: 'http://www.minetest.net/',
  },
  {
    id: 'c-12',
    title: "Olympiade belge d'informatique",
    category: 'Dès 11 ans',
    description:
      "Site web des olympiades d'informatiques belges avec plusieurs exercices de préparation disponibles pour tous",
    color: 'green',
    link: 'http://www.be-oi.be/fr/',
  },
  {
    id: 'c-13',
    title: 'Paint duel',
    category: 'Dès 11 ans',
    description:
      'Un jeu où il faut anticiper et programmer son robot-peintre afin de peindre plus de surface que son concurrent',
    color: 'green',
    link: 'https://paintduel.toxicode.fr/',
  },
  {
    id: 'c-14',
    title: 'Processing',
    category: 'Dès 11 ans',
    description: 'Logiciel de création visuelle grâce à la programmation',
    color: 'green',
    link: 'https://processing.org/',
  },
  {
    id: 'c-15',
    title: 'Silent Teacher',
    category: 'Dès 11 ans',
    description:
      'Un jeu du studio Toxicode où il faut répondre à des énigmes sans aucune indication',
    color: 'green',
    link: 'https://silentteacher.toxicode.fr/',
  },
  {
    id: 'c-16',
    title: 'Sololearn',
    category: 'Dès 11 ans',
    description:
      "Sololearn permet d'apprendre des langages de programmation avec un mélange de théorie et de pratique",
    color: 'green',
    link: 'https://www.sololearn.com/',
  },
  {
    id: 'c-17',
    title: 'Sprite Box - Android',
    category: 'Dès 11 ans',
    description:
      "Le but de cette application est d'aider un personnage à évoluer dans un monde grâce à la programmation",
    color: 'green',
    link: 'https://play.google.com/store/apps/details?id=com.lightbot.SpriteBoxCoding',
  },
  {
    id: 'c-18',
    title: 'Sprite Box - iOS',
    category: 'Dès 11 ans',
    description:
      "Le but de cette application est d'aider un personnage à évoluer dans un monde grâce à la programmation",
    color: 'green',
    link: 'https://apps.apple.com/fr/app/spritebox-code-hour/id1161515477',
  },
  {
    id: 'c-19',
    title: 'Unity',
    category: 'Dès 11 ans',
    description:
      'Unity est un logiciel de création de jeux vidéo professionnel',
    color: 'green',
    link: 'https://unity.com/',
  },
  {
    id: 'c-20',
    title: "Ville de l'algorithme",
    category: 'Dès 11 ans',
    description:
      "Application pour découvrir la logique algorithmique en s'amusant",
    color: 'green',
    link: 'https://play.google.com/store/apps/details?id=air.MusterenGames.ElHarezmiCoding',
  },
  {
    id: 'c-21',
    title: 'Kodable',
    category: 'Dès 11 ans',
    description:
      'Programme de cours via application autour de la programmation. Payant pour avoir le programme complet',
    color: 'green',
    link: 'https://www.kodable.com/',
  },
  {
    id: 'c-22',
    title: 'Khan Academy',
    category: 'Dès 11 ans',
    description:
      'Plateforme de cours en ligne gratuits dans des domaines divers',
    color: 'green',
    link: 'https://fr.khanacademy.org/',
  },
  {
    id: 'c-23',
    title: 'ESERO Belgium',
    category: 'Dès 11 ans',
    description:
      "Ressources pédagogiques sur le thème de l'espace pour le primaire et le secondaire",
    color: 'green',
    link: 'https://eserobelgium.be',
  },
  {
    id: 'd-01',
    title: 'Grasshopper',
    category: 'Dès 15 ans',
    description:
      'Application Android et iOS en anglais pour apprendre à programmer pas à pas',
    color: 'yellow',
    link: 'https://grasshopper.app/',
  },
  {
    id: 'd-02',
    title: 'Alkindi',
    category: 'Dès 15 ans',
    description:
      'Concours de cryptanalyse dont une partie est ouverte à tout le monde',
    color: 'yellow',
    link: 'https://epreuve.concours-alkindi.fr/',
  },
  {
    id: 'd-03',
    title: "Code n'Slash",
    category: 'Dès 15 ans',
    description: 'Plateforme pour créer son jeu vidéo',
    color: 'yellow',
    link: 'http://gamejam.toxicode.fr/welcome',
  },
  {
    id: 'd-04',
    title: 'Codecademy',
    category: 'Dès 15 ans',
    description:
      'Plateforme de formation autour de langages de programmation avec théorie et exercices mélangés',
    color: 'yellow',
    link: 'https://www.codecademy.com/',
  },
  {
    id: 'd-05',
    title: 'Coding Games',
    category: 'Dès 15 ans',
    description:
      'Plateforme de défis de programmation de plus en plus complexes',
    color: 'yellow',
    link: 'https://www.codingame.com/start',
  },
  {
    id: 'd-06',
    title: 'Free Code Camp',
    category: 'Dès 15 ans',
    description:
      'Cours en ligne autour de la programmation web afin de mieux comprendre le front-end',
    color: 'yellow',
    link: 'https://www.freecodecamp.org/learn/',
  },
  {
    id: 'd-07',
    title: 'Open Classrooms',
    category: 'Dès 15 ans',
    description:
      "Plateforme de cours sur des sujets divers et variés, notamment dans l'informatique",
    color: 'yellow',
    link: 'https://openclassrooms.com/fr/',
  },
  {
    id: 'd-08',
    title: 'Do not track',
    category: 'Dès 15 ans',
    description:
      'Webdocumentaire sur les traces sur le web liées à nos comportements',
    color: 'yellow',
    link: 'https://donottrack-doc.com/fr/intro/',
  },
  {
    id: 'e-01',
    title: 'Technobel',
    category: 'Encadrant',
    description:
      'Centre de compétences implanté à Ciney abritant le seul Lego Education Innovation Studio de Wallonie',
    color: 'orange',
    link: 'https://www.technobel.be/fr/',
  },
  {
    id: 'e-02',
    title: 'TechnocITé',
    category: 'Encadrant',
    description: 'Centre de compétences implanté à Mons',
    color: 'orange',
    link: 'https://www.technocite.be/',
  },
  {
    id: 'e-03',
    title: 'Technofutur TIC',
    category: 'Encadrant',
    description: "Centre de compétences basé à Gosselies et abritant l'EduLAB",
    color: 'orange',
    link: 'http://www.technofuturtic.be/',
  },
  {
    id: 'e-04',
    title: '3-6-9-12',
    category: 'Encadrant',
    description:
      'Ressource proposée par Serge Tisseron et son équipe pour encadrer les pratiques autour des écrans dans les familles mais aussi le dialogue éducatif sur cette thématique',
    color: 'orange',
    link: 'https://www.3-6-9-12.org/',
  },
  {
    id: 'e-05',
    title: 'Classe TICE',
    category: 'Encadrant',
    description:
      "Répertoire d'outils et de ressources numériques pour les apprentissages",
    color: 'orange',
    link: 'https://classetice.fr/',
  },
  {
    id: 'e-06',
    title: 'Ecole branchée',
    category: 'Encadrant',
    description:
      'Association québecoise qui développe des outils et médias pour accompagner les enseignants dans leurs pratiques numériques',
    color: 'orange',
    link: 'https://ecolebranchee.com/',
  },
  {
    id: 'e-07',
    title: 'Padlet',
    category: 'Encadrant',
    description:
      'Une plateforme pour partager des contenus collaboratifs. Version gratuite possible',
    color: 'orange',
    link: 'https://padlet.com/',
  },
  {
    id: 'e-08',
    title: 'PopLab',
    category: 'Encadrant',
    description: 'Outil pour créer des documents interactifs en ligne',
    color: 'orange',
    link: 'https://poplab.education/',
  },
  {
    id: 'e-09',
    title: 'Postclass',
    category: 'Encadrant',
    description: 'Outil de communication et de partage pour la classe',
    color: 'orange',
    link: 'https://www.postclass.net/',
  },
  {
    id: 'e-10',
    title: 'Unitag (QR code)',
    category: 'Encadrant',
    description:
      "Plateforme qui permet de créer des QR code à partir de liens et d'autres ressources",
    color: 'orange',
    link: 'https://www.unitag.io/fr/qrcode',
  },
  {
    id: 'e-11',
    title: 'Interface3',
    category: 'Encadrant',
    description:
      "Association qui oeuvre dans l'accès pour tous au numérique et à l'emploi",
    color: 'orange',
    link: 'https://www.interface3namur.be/',
  },
  {
    id: 'e-12',
    title: 'Framasoft',
    category: 'Encadrant',
    description:
      "Suite d'outils gratuits et libres allant de documents collaboratifs à une plateforme wiki en passant par des murs de post-it",
    color: 'orange',
    link: 'https://framasoft.org/fr/',
  },
  {
    id: 'e-13',
    title: 'Technifutur',
    category: 'Encadrant',
    description:
      'Centre de compétences basé à Liège avec un pôle TechniKids et TechniTeens',
    color: 'orange',
    link: 'https://technifutur.be/fr/',
  },
  {
    id: 'e-14',
    title: 'Survive on Mars',
    category: 'Encadrant',
    description:
      'Escape game créé par des enseignants français pour travailler les SVT',
    color: 'orange',
    link: 'http://surviveonmars.portail-svt.com/',
  },
  {
    id: 'e-15',
    title: 'SparkOH!',
    category: 'Encadrant',
    description:
      'SparkOH!, musée des sciences, à Frameries (Mons). Où exposition rime avec expérimentations',
    color: 'orange',
    link: 'https://pass.be/',
  },
  {
    id: 'e-16',
    title: 'Kaleidi',
    category: 'Encadrant',
    description:
      'Association basée dans la province du Hainaut travaillant autour des mathématiques et du numérique',
    color: 'orange',
    link: 'http://kaleidi.be/',
  },
  {
    id: 'e-17',
    title: "Class'code",
    category: 'Encadrant',
    description:
      'Portail de formation gratuit autour des sciences informatiques : créativité, algorithmique, robotique, projet numérique',
    color: 'orange',
    link: 'https://pixees.fr/classcode-v2/',
  },
  {
    id: 'e-18',
    title: '1,2,3 Codez!',
    category: 'Encadrant',
    description:
      "Site web accompagnant la mise en place d'activités autour de la programmation en écoles primaire et secondaire",
    color: 'orange',
    link: 'https://www.fondation-lamap.org/fr/123codez',
  },
  {
    id: 'f-01',
    title: 'Créer un jeu vidéo avec MakeCode #1',
    category: 'Kodo',
    description:
      "MakeCode est un site internet qui va te permettre de créer ton propre jeu d'arcade. Après une introduction à l'éditeur de Microsoft, suis les 3 parties du tuto pour créer ton jeu vidéo !",
    color: 'red',
    link: 'https://drive.google.com/file/d/12fYmvjNOR-doax0oB9UKl4Zicv0lVD5H/view?usp=sharing',
  },
  {
    id: 'f-02',
    title: 'Créer un jeu vidéo avec MakeCode #2',
    category: 'Kodo',
    description:
      "MakeCode est un site internet qui va te permettre de créer ton propre jeu d'arcade. Après une introduction à l'éditeur de Microsoft, suis les 3 parties du tuto pour créer ton jeu vidéo !",
    color: 'red',
    link: 'https://drive.google.com/file/d/1KV7H9e0RB0DDx1wchUZRGL2CGT07zeSr/view?usp=sharing',
  },
  {
    id: 'f-03',
    title: 'Créer un jeu vidéo avec MakeCode #3',
    category: 'Kodo',
    description:
      "MakeCode est un site internet qui va te permettre de créer ton propre jeu d'arcade. Après une introduction à l'éditeur de Microsoft, suis les 3 parties du tuto pour créer ton jeu vidéo !",
    color: 'red',
    link: 'https://drive.google.com/file/d/1l0_nmC-GIJpbcHfq3w1zC8U_qiY_V1UP/view?usp=sharing',
  },
  {
    id: 'f-04',
    title: 'Créer un jeu vidéo avec MakeCode #4',
    category: 'Kodo',
    description:
      "MakeCode est un site internet qui va te permettre de créer ton propre jeu d'arcade. Après une introduction à l'éditeur de Microsoft, suis les 3 parties du tuto pour créer ton jeu vidéo !",
    color: 'red',
    link: 'https://drive.google.com/file/d/17hTMBmEAGR6aFXZ93LY2o6oYv9TXitdz/view?usp=sharing',
  },
  {
    id: 'f-05',
    title: 'Les robots #1',
    category: 'Kodo',
    description:
      "Aujourd'hui, les robots n'appartiennent plus seulement au domaine de la science-fiction : ils font partie de notre quotidien. Ce cours mélange théorie et exercices afin de t'apprendre à différencier une machine d'un robot et de mieux comprendre ces derniers",
    color: 'red',
    link: 'https://drive.google.com/file/d/1ErO-QJ8JWuM2aERwRP8KNqaW7rC1S9-Z/view?usp=sharing',
  },
  {
    id: 'f-06',
    title: 'Les robots #2',
    category: 'Kodo',
    description:
      "Aujourd'hui, les robots n'appartiennent plus seulement au domaine de la science-fiction : ils font partie de notre quotidien. Ce cours mélange théorie et exercices afin de t'apprendre à différencier une machine d'un robot et de mieux comprendre ces derniers",
    color: 'red',
    link: 'https://drive.google.com/file/d/1u6z4to2r5y6sBaQZOnOc_1JbN6jbrlKW/view?usp=sharing',
  },
  {
    id: 'f-07',
    title: 'Les logigrammes #Elèves',
    category: 'Kodo',
    description:
      'Découvre le principe des logigrammes et apprend à en réaliser pour structurer tes idées !',
    color: 'red',
    link: 'https://drive.google.com/file/d/1C0omgswb9Lg75m7TQZvDteu3_QBSxCU4/view?usp=sharing',
  },
  {
    id: 'f-08',
    title: 'Les logigrammes #Enseignants',
    category: 'Kodo',
    description:
      'Découvre le principe des logigrammes et apprend à en réaliser pour structurer tes idées !',
    color: 'red',
    link: 'https://drive.google.com/file/d/14MsAWPJWx6B-KtFA9gsBzZwkwf416RQb/view?usp=sharing',
  },
  {
    id: 'f-09',
    title: 'La roboéthique #Elèves',
    category: 'Kodo',
    description:
      "Découvre les trois lois de la robotique d'Asimov et réfléchis à l'éthique de la robotique grâce à un mélange de débats et de jeux de rôle",
    color: 'red',
    link: 'https://drive.google.com/file/d/1tQwBtgG5_OVcYQnqum-9iFhReS92C1h6/view?usp=sharing',
  },
  {
    id: 'f-10',
    title: 'La roboéthique #Enseignants',
    category: 'Kodo',
    description:
      "Découvre les trois lois de la robotique d'Asimov et réfléchis à l'éthique de la robotique grâce à un mélange de débats et de jeux de rôle",
    color: 'red',
    link: 'https://drive.google.com/file/d/1DEI6SiXdxXtjWirfCgrzB2sWpKDi15Bq/view?usp=sharing',
  },
  {
    id: 'f-11',
    title: 'Les composants #Elèves',
    category: 'Kodo',
    description:
      "Tu te sers peut-être tous les jours d'un ordinateur. Mais sais-tu ce qui le compose réellement ?",
    color: 'red',
    link: 'https://drive.google.com/file/d/1HinYru_cTUD30oz89TZymIP5jDEoyYFA/view?usp=sharing',
  },
  {
    id: 'f-12',
    title: 'Les composants #Enseignants',
    category: 'Kodo',
    description:
      "Tu te sers peut-être tous les jours d'un ordinateur. Mais sais-tu ce qui le compose réellement ?",
    color: 'red',
    link: 'https://drive.google.com/file/d/1BAYqmp_zjsCQ4qbzym2IYKIY6Uh8_000/view?usp=sharing',
  },
  {
    id: 'f-13',
    title: 'La bureautique',
    category: 'Kodo',
    description:
      "Tu utilises un ordinateur à la maison ou à l'école et tu aimerais pouvoir t'en servir comme un•e pro ? Alors suis ces quelques conseils 'bureautique' et dégaine les raccourcis clavier plus vite que ton ombre !",
    color: 'red',
    link: 'https://drive.google.com/file/d/1DuwYC9yi9pAO-EF27MvhaSBUIU42HQ3u/view?usp=sharing',
  },
  {
    id: 'f-14',
    title: 'Les algorithmes',
    category: 'Kodo',
    description:
      "Algorithme ceci, algorithme cela… Les médias n'ont plus que ce mot à la bouche ! Grâce à ce cours, tu comprendras enfin ce qu'il signifie. Et toi, tu pourras l'utiliser correctement !",
    color: 'red',
    link: 'https://drive.google.com/file/d/1tRqHTMLkT_mOoM2O8JjLYZ2smbzr9Bap/view?usp=sharing',
  },
  {
    id: 'f-15',
    title: 'Réaliser un site internet',
    category: 'Kodo',
    description:
      "Apprends à créer ton premier site internet avec Kodo Wallonie ! Quand tu auras fini ce tutoriel, les mots 'internet' et 'web' n'auront plus de secret pour toi. Tu découvriras également les langages de programmation utilisés pour la réalisation d'un site internet",
    color: 'red',
    link: 'https://drive.google.com/file/d/1-8aiHuIRzcWHTZ080yU6Aj4fr4T9X8-x/view?usp=sharing',
  },
  {
    id: 'f-16',
    title: 'La programmation #Elèves',
    category: 'Kodo',
    description:
      "Comment communiquer avec une machine qui ne comprend pas le langage humain ? Doit-on apprendre à parler 'binaire' pour créer des programmes informatiques ? Ce cours mélange théorie et exercices afin de t'apprendre ce qu'est la programmation informatique",
    color: 'red',
    link: 'https://drive.google.com/file/d/1w3qAzRqbB35s63Z27VOlycgdKutjKVZE/view?usp=sharing',
  },
  {
    id: 'f-17',
    title: 'La programmation #Enseignants',
    category: 'Kodo',
    description:
      "Comment communiquer avec une machine qui ne comprend pas le langage humain ? Doit-on apprendre à parler 'binaire' pour créer des programmes informatiques ? Ce cours mélange théorie et exercices afin de t'apprendre ce qu'est la programmation informatique",
    color: 'red',
    link: 'https://drive.google.com/file/d/1s1DrY-sZV_F5-P_0STxq4KFYX3Hs0RJs/view?usp=sharing',
  },
  {
    id: 'f-18',
    title: 'Construct 3 #Enseignants',
    category: 'Kodo',
    description:
      "Réaliser un jeu vidéo en classe avec Construct 3 ? C'est possible et nous vous fournissons un tutoriel adapté pour la classe.",
    color: 'red',
    link: 'https://drive.google.com/file/d/1TdBRLM_R61wB-5oHkgMWyCb5-EJ3PSnx/view?usp=sharing',
  },
  {
    id: 'f-19',
    title: 'Construct 3 #Elèves',
    category: 'Kodo',
    description:
      'Autonomisez vos élèves avec cette fiche de rappel : elle reprend toutes les informations pour développer un jeu vidéo simple.',
    color: 'red',
    link: 'https://drive.google.com/file/d/15G5uVZoea10K5VUacVFl_bzNaF_Z7z2P/view?usp=sharing',
  },
  {
    id: 'f-20',
    title: "Les dessous de l'animation numérique #Elèves",
    category: 'Kodo',
    description:
      "Avec ce carnet, l'équipe de Kodo Wallonie vous propose de découvrir l'animation en deux dimensions grâce à un outil simple et gratuit. Il est accompagné du guide enseignant disponible également dans les « Ressources ».",
    color: 'red',
    link: 'https://drive.google.com/file/d/1ISzTtmz4BBiNvjjfpubSjyq7csQnYFfI/view?usp=sharing',
  },
  {
    id: 'f-21',
    title: "L'éveil technologique et social avec Minecraft #Elèves",
    category: 'Kodo',
    description:
      'Le carnet pédagogique sur Minecraft Education est un guide amusant et éducatif pour les jeunes qui veulent explorer le monde merveilleux de Minecraft.',
    color: 'red',
    link: 'https://drive.google.com/file/d/1O5lBvtEe4q2QQxaN593q6T9F1v8mvYw4/view?usp=sharing',
  },
  {
    id: 'f-22',
    title: "Création d'un jeu vidéo #Elèves",
    category: 'Kodo',
    description:
      "Ce carnet vous fera découvrir l'univers riche est complexe qu'est le jeu vidéo.",
    color: 'red',
    link: 'https://drive.google.com/file/d/1cAXJ2PIauKWE-L5RKydLMHctlPpIr9vF/view?usp=sharing',
  },
  {
    id: 'f-23',
    title: "L'électronique de Micro:bit à Arduino #Elèves",
    category: 'Kodo',
    description:
      'Avec ce carnet de l’élève, l’équipe de Kodo Wallonie vous propose de découvrir l’électronique avec votre classe au travers de plusieurs projets. Il est accompagné du guide enseignant disponible également dans les « Ressources ».',
    color: 'red',
    link: 'https://drive.google.com/file/d/1Y_akI1x3i5LYNfObGwihdKE5doURFX0l/view?usp=sharing',
  },
  {
    id: 'f-24',
    title: "L'électronique de Micro:bit à Arduino #Enseignants",
    category: 'Kodo',
    description:
      'Ce guide de l’enseignant vous permettra d’accompagner vos élèves dans les projets disponibles au sein du carnet du même nom et disponible dans cette même section du site.',
    color: 'red',
    link: 'https://drive.google.com/file/d/1LnyIELZXv07KoYh1834ovWTt13YfNQwv',
  },
  {
    id: 'f-25',
    title: "Les dessous de l'animation numérique #Enseignants",
    category: 'Kodo',
    description:
      'Ce guide de l’enseignant vous permettra d’accompagner vos élèves dans les projets disponibles au sein du carnet du même nom et disponible dans cette même section du site.',
    color: 'red',
    link: 'https://drive.google.com/file/d/1LcspH8McNc0byG5Specjk34_82-CShcO',
  },
];

export default linkData;
