/* eslint-disable react/style-prop-object */
const Logo = () => {
  return (
    <>
      <svg version="1.1" id="Calque_1" x="0px" y="0px" viewBox="0 0 502 122">
        <path
          class="st0"
          d="M172.5,0.6c-33.3,0-60.4,27-60.4,60.4s27,60.4,60.4,60.4s60.4-27,60.4-60.4C232.9,27.7,205.9,0.6,172.5,0.6z
	M172.5,93.5C154.6,93.5,140,79,140,61c0-18,14.6-32.5,32.5-32.5C190.5,28.5,205,43,205,61C205,78.9,190.5,93.5,172.5,93.5z"
        />
        <path
          class="st0"
          d="M89.5,97.6L52.9,61l16.8-16.8L52.9,61l16.8-16.8l19.8-19.8c2.5-2.5,4.1-6,4.1-9.8c0-7.7-6.2-13.9-13.9-13.9
	c-3.9,0-7.3,1.6-9.9,4.1L33.2,41.3l-1.4,1.4c-1.1,1.1-2.1,2.3-2.9,3.5v-0.6V14.6c0-7.7-6.2-13.9-13.9-13.9S1,6.9,1,14.6v92.9
	c0,7.7,6.2,13.9,13.9,13.9s13.9-6.2,13.9-13.9v-5.5c4-1,7-4.7,7-9s-3-8-7-9c-0.7-0.2-1.5-0.3-2.3-0.3c-0.8,0-1.6,0.1-2.3,0.3
	c-4,1-7,4.7-7,9c0,0.3,0,0.5,0,0.8c0.2,2.6,1.5,4.8,3.4,6.4c0.1,0.1,0.1,0.1,0.2,0.2c1,0.8,2.1,1.3,3.4,1.7v5.5
	c0,5.1-4.1,9.3-9.3,9.3c-5.1,0-9.3-4.2-9.3-9.3V97V24.9V14.6c0-5.1,4.2-9.3,9.3-9.3s9.3,4.1,9.3,9.3v10.4v25.3V61
	c0,3.6,0.7,7.1,2.2,10.4c0.7,1.5,1.5,3,2.5,4.4c0.8,1.1,1.7,2.2,2.7,3.3c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0,0,0.1,0.1c0,0,0,0,0,0
	s0,0,0,0c0.1,0.1,0.1,0.1,0.2,0.2l1.1,1.2l28.5,28.4l5.1,5.1c0-0.2,0-0.3,0-0.5c0,0.2,0,0.4,0,0.5l3,3c2.5,2.5,6,4.1,9.9,4.1
	c7.7,0,13.9-6.2,13.9-13.9C93.6,103.6,92,100.1,89.5,97.6z M24.2,97c-0.2-0.1-0.4-0.3-0.6-0.4c-0.1-0.1-0.2-0.2-0.3-0.3
	c-1.8-1.8-1.8-4.8,0-6.6c0.3-0.3,0.6-0.5,1-0.7c1.4-0.8,3.2-0.8,4.6,0c0.4,0.2,0.7,0.4,1,0.7c1.8,1.8,1.8,4.8,0,6.6
	c-0.3,0.3-0.6,0.5-1,0.7C27.4,97.8,25.6,97.8,24.2,97z"
        />
        <path
          class="st0"
          d="M440.6,0.6c-33.3,0-60.4,27-60.4,60.4s27,60.4,60.4,60.4c33.3,0,60.4-27,60.4-60.4S474,0.6,440.6,0.6z
	M440.6,93.5c-18,0-32.5-14.6-32.5-32.5c0-18,14.5-32.5,32.5-32.5c18,0,32.5,14.5,32.5,32.5C473.1,78.9,458.6,93.5,440.6,93.5z"
        />
        <path
          class="st0"
          d="M322.7,4.5C316,2,308.8,0.6,301.3,0.6h-40.6c-5.1,0-9.3,4.1-9.3,9.3V76v4.2v31.9c0,5.1,4.1,9.3,9.3,9.3h40.6
	c33.3,0,60.4-27,60.4-60.4C361.7,35.2,345.5,13.2,322.7,4.5z M301.3,93.5h-22v-65h22c18,0,32.5,14.6,32.5,32.5
	S319.3,93.5,301.3,93.5z"
        />
      </svg>
    </>
  );
};

export default Logo;
