import { useEffect, useRef, useState } from 'react';
import dataBlog from '../data/dataBlog';
import { Link, useParams } from 'react-router-dom';
import PostContent from './PostContent';

const PostHead = () => {
  let params = useParams();
  let post = dataBlog[params.id];

  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    });
    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [isIntersecting]);

  useEffect(() => {
    if (isIntersecting) {
      document.body.classList.add('bg-yellow');
    } else {
      document.body.classList.remove('bg-yellow');
    }
  }, [isIntersecting]);
  return (
    <>
      <section className="section" ref={ref}>
        <div className="wrapper small">
          <div className="container column">
            <div className="head">
              <h2 className="title-alt">{post.titleAlt}</h2>
              <h3>{post.published_date}</h3>
              <p className="chapo">{post.chapo}</p>
              <img src={post.cover} alt="post" />
              <p className="credit">© {post.credit}</p>
            </div>
            <PostContent />
            <div className="post-btn ma">
              <Link to="/blog" className="link-btn">
                Tous nos articles
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PostHead;
