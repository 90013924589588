const Facebook = () => {
  return (
    <>
      <svg x="0px" y="0px" viewBox="0 0 267 266.9">
        <path
          class="st0"
          d="M248.1,4.6H18.8C11,4.6,4.6,11,4.6,18.8v229.3c0,7.9,6.4,14.2,14.2,14.2h123.5v-99.8h-33.6v-38.9h33.6V94.9
  c0-33.3,20.3-51.4,50-51.4c14.2,0,26.4,1,30,1.5v34.9h-20.6c-16.2,0-19.3,7.6-19.3,18.9v24.8h38.5l-5,38.9h-33.5v99.8h65.7v0
	c7.9,0,14.2-6.4,14.2-14.2V18.8C262.3,10.9,255.9,4.6,248.1,4.6z"
        />
      </svg>
    </>
  );
};

export default Facebook;
