/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import dataEvent from '../data/dataEvent';
import '../styles/_event.css';

const Event = () => {
  let listItems = dataEvent.map(function (event, index) {
    if (event.state == 'new') {
      return (
        <div id={event.class} className="g052o2gw9p" key={index}>
          <a href={event.link} target="_blank" rel="noreferrer">
            <p className="kv3rj3x348">{event.date}</p>
            <p className="kyx09ln564">{event.title}</p>
            <p className="d6a0s6g75u">{event.place}</p>
          </a>
          <img src="svg/link.svg" alt="link" />
        </div>
      );
    }
  });
  return <>{listItems}</>;
};

export default Event;
