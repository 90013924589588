let eventData = [
  /*
  {
    date: '',
    title: '',
    place: '',
    link: '',
    class: '',
    state: '',
  },
  */
  {
    date: 'Dès le 5 septembre 2022',
    title: 'Dans le cadre de Technokids',
    place: 'Technocampus de Gosselies',
    link: 'https://www.technocampus.be/technokids-et-technoteens/',
    class: '',
    state: 'new',
  },
   {
    date: '4 juin 2024',
    title: "Atelier robotique",
    place: 'AR Meuse Condroz à Andenne',
    link: '',
    class: 'disabled',
    state: 'new',
  },
    {
    date: '5 juin 2024',
    title: "Atelier robotique",
    place: 'Le Chêneux à Amay',
    link: '',
    class: 'disabled',
    state: 'new',
  },
    {
    date: '6 juin 2024',
    title: "Atelier création stop motion",
    place: 'EFC Sart Tilman',
    link: '',
    class: 'disabled',
    state: 'new',
  },
    {
    date: '7 juin 2024',
    title: "Cycle Minecraft",
    place: 'Simila Asbl',
    link: '',
    class: 'disabled',
    state: 'new',
  },
   {
    date: '11 juin 2024',
    title: "Atelier robotique",
    place: 'AR Meuse Condroz à Andenne',
    link: '',
    class: 'disabled',
    state: 'new',
  },
   {
    date: '12 et 13 juin 2024',
    title: "Atelier création stop motion",
    place: 'EFC Sart Tilman',
    link: '',
    class: 'disabled',
    state: 'new',
  },
   {
    date: '14 juin 2024',
    title: "Cycle Minecraft",
    place: 'Simila Asbl',
    link: '',
    class: 'disabled',
    state: 'new',
  },
  {
    date: '18 juin 2024',
    title: "Atelier robotique",
    place: 'AR Meuse Condroz à Andenne',
    link: '',
    class: 'disabled',
    state: 'new',
  },
  {
    date: '20 juin 2024',
    title: "Atelier création stop motion",
    place: 'EFC Sart Tilman',
    link: '',
    class: 'disabled',
    state: 'new',
  },
  {
    date: '25 juin 2024',
    title: "Atelier robotique",
    place: 'AR Waremme',
    link: '',
    class: 'disabled',
    state: 'new',
  },
   {
    date: '3 juillet 2024',
    title: 'Atelier Minecraft dans le cadre de Délibère toi',
    place: "Braine l'Alleud",
    link: 'https://www.deliberetoi.be/stage/FO544',
    class: '',
    state: 'new',
  },
  {
    date: '4 juillet 2024',
    title: 'Atelier électronique dans le cadre de Délibère toi',
    place: 'Jodoigne',
    link: 'https://www.deliberetoi.be/stage/FO545',
    class: '',
    state: 'new',
  },
   {
    date: '5 juillet 2024',
    title: "Atelier création d'un jeu vidéo dans le cadre de Délibère toi",
    place: 'Ottignies',
    link: 'https://www.deliberetoi.be/stage/FO546',
    class: '',
    state: 'new',
  },
   {
    date: 'Du 15 au 19 juillet 2024',
    title: "Stage multinumérique pour les enfants",
    place: 'Seneffe',
    link: 'https://seneffe.antopolis.be/event/stage-informatique-2024-07-15-2024-07-19-255/register',
    class: '',
    state: 'new',
  },
  {
    date: 'Du 22 au 26 juillet 2024',
    title: "Stage multinumérique pour les ados",
    place: 'Couvin',
    link: 'https://mj404asbl.jimdofree.com',
    class: '',
    state: 'new',
  },
  {
    date: 'Du 19 au 23 août 2024',
    title: "Stage multinumérique pour les enfant",
    place: 'Centre Culturel de Huy',
    link: 'https://centrecultureldehuy.be/agenda/jeux-video-arts-numeriques/',
    class: '',
    state: 'new',
  },
  {
    date: 'Du 26 février au 1er mars 2024',
    title: "Stage de création d'un jeu vidéo pour les enfants",
    place: 'Seneffe',
    link: 'https://seneffe.antopolis.be/event/viens-creer-ton-propre-jeu-video-2024-02-26-2024-03-01-229/register',
    class: '',
    state: 'old',
  },
  {
    date: 'Du 4 au 8 mars 2024',
    title: "Stage de création d'un jeu vidéo pour les enfants",
    place: 'Héron',
    link: 'https://www.heron.be/uploads/7/9/0/3/79032842/brochure_atl_2024-2.pdf',
    class: '',
    state: 'old',
  },
  {
    date: '12 mars 2024',
    title: "Atelier Minecraft pour les jeunes du centre d'accueil L'Entre 2",
    place: 'Clinique CHC Montlégia',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: 'Les jeudis de mars 2024',
    title: 'Atelier Minecraft',
    place: 'Ecole Sainte-Begge',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '15 mars 2024',
    title:
      'Animation jeu vidéo dans le cadre de "Des métiers au bout des doigts"',
    place: 'Namur',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '19 mars 2024',
    title:
      'Animation jeu vidéo dans le cadre de "Des métiers au bout des doigts"',
    place: 'Namur',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '20 mars 2024',
    title: "Atelier Minecraft pour les jeunes du centre d'accueil L'Entre 2",
    place: ' Clinique CHC Montlégia',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '23 mars 2024',
    title:
      'De Mars à la Terre, trajet direct en 258 jours - Atelier dans le cadre du Printemps des Sciences de Namur',
    place: 'Namur',
    link: 'https://pds.unamur.be',
    class: '',
    state: 'old',
  },
  {
    date: '24 mars 2024',
    title:
      'De Mars à la Terre, trajet direct en 258 jours - Atelier dans le cadre du Printemps des Sciences de Louvain-la-Neuve',
    place: 'Louvain-la-Neuve',
    link: 'https://www.printempsdessciencesucl.be/evenement/7',
    class: '',
    state: 'old',
  },
  {
    date: '25 mars 2024',
    title: 'Atelier robotique',
    place: 'Ecole communale de Bonsin',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '26 mars 2024',
    title: "Atelier Minecraft pour les jeunes du centre d'accueil L'Entre 2",
    place: 'Clinique CHC Montlégia',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '29 mars 2024',
    title: 'Atelier robotique',
    place: 'Ecole communale de Bonsin',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: 'Les jeudis de mars et avril 2024',
    title: 'Atelier Stop-motion',
    place: 'Agri-Saint-Georges-Sur-Meuse',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: 'Les mercredis de mars et avril 2024',
    title: 'Atelier Escape Game pour des 3e secondaire',
    place: 'Saint-Louis Waremme',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: "Les mardis d'avril 2024",
    title: "Atelier création d'un jeu vidéo",
    place: 'Ecole de la Providence Champion',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '2 avril 2024',
    title: 'Atelier création 3D',
    place: 'Institut Sainte-Marie de Jemeppe',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '4 avril 2024',
    title: 'Atelier Scratch',
    place: 'Ecole Saint-Dominique de Saint-Nicolas',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '8 avril 2024',
    title: 'Atelier Stop-motion',
    place: 'Ecole Communale Pastur de Chapelle-lez-Herlaimont',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '9 avril 2024',
    title: 'Atelier création 3D',
    place: 'Institut Sainte-Marie de Jemeppe',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '10 avril 2024',
    title: "Atelier Minecraft pour les jeunes du centre d'accueil L'Entre 2",
    place: 'Clinique CHC Montlégia',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '11 avril 2024',
    title: 'Atelier Scratch',
    place: 'Ecole Saint-Dominique de Saint-Nicolas',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '11 avril 2024',
    title: 'Atelier Micro:bit',
    place: 'Ecole Communale de Verlaine-sur-Sambre',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '12 avril 2024',
    title: 'Atelier robotique',
    place: 'Ecole communale de Bonsin',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '15 avril 2024',
    title: 'Atelier Stop-motion',
    place: 'Ecole Communale Pastur de Chapelle-lez-Herlaimont',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '18 avril 2024',
    title: 'Atelier Scratch',
    place: 'Ecole Saint-Dominique de Saint-Nicolas',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '18 avril 2024',
    title: 'Atelier Micro:bit',
    place: 'Ecole Communale de Verlaine-sur-Sambre',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '20 et 21 avril 2024',
    title: "Salon Robotix's de SparkOH!",
    place: 'Mons',
    link: 'https://sparkoh.be/projet-robotixs/',
    class: '',
    state: 'old',
  },
  {
    date: '22 avril 2024',
    title: 'Atelier Stop-motion',
    place: 'Ecole Communale Pastur de Chapelle-lez-Herlaimont',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '23 avril 2024',
    title: 'Atelier Micro:bit',
    place: "Ecole Communale d'Attert",
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '25 avril 2024',
    title: 'Atelier Scratch',
    place: 'Ecole Saint-Dominique de Saint-Nicolas',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '25 avril 2024',
    title: 'Atelier Micro:bit',
    place: 'Ecole Communale de Verlaine-sur-Sambre',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '26 avril 2024',
    title:
      'Animation jeu vidéo dans le cadre de "Des métiers au bout des doigts"',
    place: 'Namur',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '26 avril 2024',
    title: 'Atelier découverte des cartes programmables',
    place: 'Institut Sainte Julie de Marche en Famenne',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: 'Du 6 au 10 mai 2024',
    title: 'Stage de création 3D pour les ados',
    place: 'Libin',
    link: 'https://seneffe.antopolis.be/event/viens-creer-ton-propre-jeu-video-2024-02-26-2024-03-01-229/register',
    class: '',
    state: 'old',
  },
  {
    date: 'Les mardis de janvier et février 2024',
    title: 'Initiation au numérique',
    place: 'Institut des Frères Maristes Mouscron',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: 'Les mercredis de janvier et février 2024',
    title: 'Atelier Scratch',
    place: 'Ecole communale de Besonrieux',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: 'Les jeudis de janvier et février 2024',
    title: 'Atelier robotique',
    place: 'Don Bosco Saint-Georges-sur-Meuse',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '8 février 2024',
    title:
      'Animation jeu vidéo dans le cadre de "Des métiers au bout des doigts"',
    place: 'Namur',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '22 décembre 2023',
    title: 'Atelier Scratch',
    place: 'Ecole Saint-Dominique de Saint-Nicolas',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '19 décembre 2023',
    title: 'Atelier Scratch',
    place: 'Ecole Saint-Dominique de Saint-Nicolas',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '15 décembre 2023',
    title: 'Atelier Scratch',
    place: 'Ecole Saint-Dominique de Saint-Nicolas',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '12 décembre 2023',
    title: 'Atelier Scratch',
    place: 'Ecole Saint-Dominique de Saint-Nicolas',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '30 novembre 2023',
    title: 'Atelier robotique',
    place: 'Ecole communale de Villance',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '1er décembre 2023',
    title: 'Atelier Scratch',
    place: 'Athénée Royal de Waremme',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '30 novembre 2023',
    title: 'Atelier robotique',
    place: 'Ecole communale de Villance',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '29 novembre 2023',
    title: 'Atelier création en stop-motion',
    place: 'Conseil du Château Pastur de Jodoigne',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '28 novembre 2023',
    title: 'Atelier robotique',
    place: "Ecole communale d'Aubange",
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '15 novembre 2023',
    title: "Atelier création d'un jeu vidéo",
    place: 'Conseil du Château Pastur de Jodoigne',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '14 novembre 2023',
    title: "Atelier création d'un jeu vidéo",
    place: 'Ecole de la Providence Champion',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '10 novembre 2023',
    title: "Salon wallon de l'inclusion numérique",
    place: 'Aula Magna, Louvain-la-Neuve',
    link: 'https://osonslenumerique.wallonie.be/',
    class: '',
    state: 'old',
  },
  {
    date: '9 novembre 2023',
    title: 'Salon Eneo - Tremplin vers le numérique',
    place: "Centre Culturel d'Ans",
    link: 'https://www.eneo.be/agenda/?wid_c=9&wid_a=820&wstyle=eneo',
    class: '',
    state: 'old',
  },
  {
    date: '7 novembre 2023',
    title: "Atelier création d'un jeu vidéo",
    place: 'Ecole de la Providence Champion',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: 'Du 1er au 3 novembre 2023',
    title: 'Ludovia#BE',
    place: 'Spa',
    link: '[https://ludovia.be](https://ludovia.be/)',
    class: '',
    state: 'old',
  },
  {
    date: 'Du 23 au 27 octobre 2023',
    title:
      "Stage de création d'un jeu vidéo et découverte de Minecraft Education",
    place: 'Héron',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '20 octobre 2023',
    title: 'Atelier robotique',
    place: 'Le Menolou - CHU Tivoli',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '17 octobre 2023',
    title: "Atelier création d'un jeu vidéo",
    place: 'Ecole de la Providence Champion',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '13 octobre 2023',
    title: 'Atelier robotique',
    place: 'Le Menolou - CHU Tivoli',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '10 octobre 2023',
    title: "Atelier création d'un jeu vidéo",
    place: 'Ecole de la Providence Champion',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '6 octobre 2023',
    title: 'Atelier robotique',
    place: 'Le Menolou - CHU Tivoli',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '2 octobre 2023',
    title: 'Atelier Minecraft',
    place: 'Ecole Les Moineaux - Wavre',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '29 septembre 2023',
    title: 'Atelier robotique',
    place: 'Le Menolou - CHU Tivoli',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '26 septembre 2023',
    title: "Atelier création d'un jeu vidéo",
    place: 'Ecole de la Providence Champion',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '25 septembre 2023',
    title: 'Atelier Minecraft',
    place: 'Ecole Les Moineaux - Wavre',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '22 septembre 2023',
    title: 'Atelier robotique',
    place: 'Le Menolou - CHU Tivoli',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '19 septembre 2023',
    title: "Atelier création d'un jeu vidéo",
    place: 'Ecole de la Providence Champion',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '18 septembre 2023',
    title: 'Atelier Minecraft',
    place: 'Ecole Les Moineaux - Wavre',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: 'Du 21 au 25 août 2023',
    title: 'Stage au Centre culturel de Huy',
    place: 'Huy',
    link: '[https://centrecultureldehuy.be](https://centrecultureldehuy.be/)',
    class: '',
    state: 'old',
  },
  {
    date: 'Du 7 au 11 août 2023',
    title: 'Stage au Centre culturel de Huy',
    place: 'Huy',
    link: '[https://centrecultureldehuy.be](https://centrecultureldehuy.be/)',
    class: '',
    state: 'old',
  },
  {
    date: 'Du 31 juillet au 4 août 2023',
    title: 'Stage au Crealab',
    place: 'Liège',
    link: 'https://www.facebook.com/crealabprovincedeliege/',
    class: '',
    state: 'old',
  },
  {
    date: 'Du 17 au 20 juillet 2023',
    title: "Stage à l'EPN de Brunehaut",
    place: 'Brunehaut',
    link: 'https://www.brunehaut.be/index.php?page=1056',
    class: '',
    state: 'old',
  },
  {
    date: 'Du 10 au 14 juillet 2023',
    title: 'Stage au Crealab',
    place: 'Liège',
    link: 'https://www.facebook.com/crealabprovincedeliege/',
    class: '',
    state: 'old',
  },
  {
    date: '4 juillet 2023',
    title: 'Atelier Minecraft dans le cadre de Délibère-toi',
    place: 'Court-Saint-Étienne',
    link: 'https://www.deliberetoi.be/stage/FO526',
    class: '',
    state: 'old',
  },
  {
    date: '3 juillet 2023',
    title: 'Atelier Minecraft dans le cadre de Délibère-toi',
    place: 'Grez-doiceau',
    link: 'https://www.deliberetoi.be/stage/CU119',
    class: '',
    state: 'old',
  },
  {
    date: '1er juin 2023',
    title:
      'Animation jeu vidéo dans le cadre de "Des métiers au bout des doigts"',
    place: 'Namur',
    link: 'https://www.bassinefe-namur.be/sites/default/files/dmbd4_catalogue_vd.pdf/',
    class: '',
    state: 'old',
  },
  {
    date: '23 mai 2023',
    title: 'Visite autour du jeu vidéo',
    place: 'AR Les Marlaires Charleroi',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: 'Du 8 au 12 mai 2023',
    title: 'Stage de création de jeux vidéo au centre culturel',
    place: "Braine-l'Alleud",
    link: 'https://www.braineculture.be/evenement/STAGE--Une-semaine-pour-creer-ton-jeu-video_n75618706',
    class: '',
    state: 'old',
  },
  {
    date: 'Du 2 au 5 mai 2023',
    title: 'Stage 3D au centre culturel',
    place: 'Saint-Georges-sur-Meuse',
    link: '[https://www.saintgeorgesculture.be/stages/stage-numérique-ado-créations-en-3d](https://www.saintgeorgesculture.be/stages/stage-num%C3%A9rique-ado-cr%C3%A9ations-en-3d)',
    class: '',
    state: 'old',
  },
  {
    date: "Les mercredis d'avril et de mai 2023",
    title: 'Atelier de création de jeux vidéo et autour de Minecraft',
    place: "École d'enseignement spécialisé Le Chêneux - Amay",
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '28 avril 2023',
    title: 'AR Paul Delvaux',
    place: 'Ottignies-Louvain-la-Neuve',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: 'Du 21 au 23 avril 2023',
    title: 'Animations scolaires et salon',
    place: 'Dans le cadre du projet "Robotix\'s" de SparkOH!',
    link: 'https://sparkoh.be/projet-robotixs/robotixs/',
    class: '',
    state: 'old',
  },
  {
    date: '21 avril 2023',
    title: 'Dans le cadre de "Des métiers au bout des doigts"',
    place: 'Namur',
    link: 'https://www.bassinefe-namur.be/sites/default/files/dmbd4_catalogue_vd.pdf/',
    class: '',
    state: 'old',
  },
  {
    date: '21 avril 2023',
    title: 'AR Paul Delvaux',
    place: 'Ottignies-Louvain-la-Neuve',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '20 avril 2023',
    title: 'Dans le cadre de "Des métiers au bout des doigts"',
    place: 'Namur',
    link: 'https://www.bassinefe-namur.be/sites/default/files/dmbd4_catalogue_vd.pdf/',
    class: '',
    state: 'old',
  },
  {
    date: '6 avril 2023',
    title: 'Dans le cadre de "Des métiers au bout des doigts"',
    place: 'Namur',
    link: 'https://www.bassinefe-namur.be/sites/default/files/dmbd4_catalogue_vd.pdf/',
    class: '',
    state: 'old',
  },
  {
    date: '3 avril 2023',
    title: 'Collège Notre-Dame des 3 Vallées',
    place: 'La Hulpe',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: 'Les vendredis de mars et avril 2023',
    title: 'École Saint-Begge',
    place: 'Seilles',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '27 mars 2023',
    title: 'AR Paul Delvaux',
    place: 'Ottignies-Louvain-la-Neuve',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '26 mars 2023',
    title: 'Escape Game dans le cadre du Printemps des Sciences',
    place: 'Liège',
    link: 'https://www.rejouisciences.uliege.be/cms/c_16797932/fr/printemps-des-sciences-2023-diversites/',
    class: '',
    state: 'old',
  },
  {
    date: '25 mars 2023',
    title: 'Escape Game dans le cadre du Printemps des Sciences',
    place: 'Namur',
    link: 'https://pds.unamur.be/copy_of_grandpublic/',
    class: '',
    state: 'old',
  },
  {
    date: '10 mars 2023',
    title: 'Dans le cadre de "Des métiers au bout des doigts"',
    place: 'Namur',
    link: 'https://www.bassinefe-namur.be/sites/default/files/dmbd4_catalogue_vd.pdf/',
    class: '',
    state: 'old',
  },
  {
    date: '6 mars 2023',
    title: 'AR Paul Delvaux',
    place: 'Ottignies-Louvain-la-Neuve',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '10 février 2023',
    title: "Formation FMTTN à l'HELHa",
    place: 'Louvain-la-Neuve',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: 'Dès le 9 février 2023',
    title: 'Activités électroniques et robotiques pour les 3e et 4e TT',
    place: 'Institut Notre-Dame - Bertrix',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: 'Tous les mardis dès le 7 février 2023',
    title: "École d'enseignement spécialisé Maurice Heuse",
    place: 'Verviers',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: '2 février 2023',
    title: 'Dans le cadre de "Des métiers au bout des doigts"',
    place: 'Namur',
    link: 'https://www.bassinefe-namur.be/sites/default/files/dmbd4_catalogue_vd.pdf/',
    class: '',
    state: 'old',
  },
  {
    date: 'Un vendredi par mois dès le 21 octobre 2022',
    title: 'École en Couleurs',
    place: 'Clinique CHC MontLégia',
    link: '',
    class: 'disabled',
    state: 'old',
  },
  {
    date: 'Dès le 9 septembre 2022',
    title: "Création d'un jeu vidéo historique",
    place: 'École Communale Georges Mignon de Liège',
    link: '',
    class: 'disabled',
    state: 'old',
  },
];
export default eventData;
